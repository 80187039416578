import petrBromnik from "@assets/images/members/bromnik-petr.jpeg?w=180&webp&imagetools";
import ondrejCzubaj from "@assets/images/members/czubaj-ondrej.jpg?w=180&webp&imagetools";
import jakubHyza from "@assets/images/members/hyza-jakub.jpeg?w=180&webp&imagetools";
import adrianaJackova from "@assets/images/members/jackova-adriana.jpg?w=180&webp&imagetools";
import richardJelinek from "@assets/images/members/jelinek-richard.jpg?w=180&webp&imagetools";
import martinKucera from "@assets/images/members/kucera-martin.jpg?w=180&webp&imagetools";
import mojmirKyjonka from "@assets/images/members/kyjonka-mojmir.jpg?w=180&webp&imagetools";
import davidMacek from "@assets/images/members/macek-david.jpg?w=180&webp&imagetools";
import jakubMacek from "@assets/images/members/macek-jakub.jpeg?w=180&webp&imagetools";
import janMozny from "@assets/images/members/mozny-jan.jpeg?w=180&webp&imagetools";
import tomasPala from "@assets/images/members/pala-tomas.jpg?w=180&webp&imagetools";
import denisPys from "@assets/images/members/pys-denis.jpg?w=180&webp&imagetools";
import michalSimon from "@assets/images/members/simon-michal.jpeg?w=180&webp&imagetools";
import radekSotkovsky from "@assets/images/members/sotkovsky-radek.jpg?w=180&webp&imagetools";
import jakubTyson from "@assets/images/members/tyson-jakub.jpg?w=180&webp&imagetools";
import matejVenglar from "@assets/images/members/venglar-matej.jpg?w=180&webp&imagetools";
import type { ContactPerson } from "@components/ContactPerson.js";
import type { Tenant } from "@core/schema/Tenant.js";

export enum TeamMemberType {
	Internist = "Internist",
	Externist = "Externist",
}

export function getTeamMembers(tenant: Tenant): ContactPerson[] {
	return [
		{
			name: "Jakub Tysoň",
			role: "Co-founder & Developer",
			photo: jakubTyson,
			type: TeamMemberType.Internist,
			phone: "+420 731 956 030",
			email: "tyson@fastest.cz",
			linkedin: "jakub-tyson",
			show: true,
		},
		{
			name: "Martin Kučera",
			role: "Manager & Developer",
			photo: martinKucera,
			phone: "+420 773 050 550",
			email: "kucera@fastest.cz",
			linkedin: "kucera-martin",
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Tomáš Pala",
			role: "QA Engineer & Tech support",
			photo: tomasPala,
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Adriána Jacková",
			role: "QA Engineer",
			photo: adrianaJackova,
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Richard Jelínek",
			role: "Fullstack Developer",
			photo: richardJelinek,
			type: TeamMemberType.Internist,
			show: true,
		},

		{
			name: "Michal Šimon",
			role: "DevOps Engineer",
			photo: michalSimon,
			type: TeamMemberType.Externist,
			show: false,
		},
		{
			name: "Mojmír Kyjonka",
			role: "Fullstack Developer",
			photo: mojmirKyjonka,
			type: TeamMemberType.Internist,
			show: true,
		},

		{
			name: "David Macek",
			role: "Architect, Analyst & Developer",
			photo: davidMacek,
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Petr Bromnik",
			role: "Frontend Developer",
			photo: petrBromnik,
			type: TeamMemberType.Internist,
			show: false,
		},
		{
			name: "Ondřej Czubaj",
			role: "Art Director",
			photo: ondrejCzubaj,
			type: TeamMemberType.Externist,
			show: true,
		},
		{
			name: "Jakub Hýža",
			role: "Frontend Developer",
			photo: jakubHyza,
			type: TeamMemberType.Internist,
			show: false,
		},

		{
			name: "Jakub Kecam",
			role: "Analyst",
			photo: jakubMacek,
			type: TeamMemberType.Externist,
			show: false,
		},
		{
			name: "Radek Šotkovský",
			role: "Fullstack Developer",
			photo: radekSotkovsky,
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Jan Možný",
			role: "Junior Developer",
			photo: janMozny,
			type: TeamMemberType.Internist,
			show: false,
		},

		{
			name: "Denis Pyš",
			role: "Junior Developer",
			photo: denisPys,
			type: TeamMemberType.Internist,
			show: true,
		},

		{
			name: "Matěj Venglář",
			role: "Trainee",
			photo: matejVenglar,
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Roman Tarnai",
			role: "Junior Developer",
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Jonáš Brauer",
			role: "Fullstack Developer",
			type: TeamMemberType.Internist,
			show: true,
		},
		{
			name: "Adéla Hrazdílková",
			role: "Project Manager",
			type: TeamMemberType.Internist,
			show: false,
		},
		{
			name: "Michal Kubricht",
			role: "Backend Developer",
			type: TeamMemberType.Externist,
			show: true,
		},
		{
			name: "Martin Schwarz",
			role: "Copywriter",
			type: TeamMemberType.Externist,
			show: true,
		},
		{
			name: "Vojtěch Beil",
			role: "Backend Developer",
			type: TeamMemberType.Externist,
			show: false,
		},
		{
			name: "Martina Libřická",
			role: "SEO Specialist",
			type: TeamMemberType.Externist,
			show: true,
		},
		{
			name: "Simona Hůrková",
			role: "UI/UX Designer",
			type: TeamMemberType.Externist,
			show: false,
		},
		{
			name: "Tomáš Hrabec",
			role: "UX Designer",
			type: TeamMemberType.Externist,
			show: false,
		},
	];
}
